.rdp-text-overlay {
  & > div {
    display: none;
  }
}

.rmc-picker-mask {
  background-image: none !important;
}

.rmc-picker-indicator {
  border: none;
  top: 35px !important;
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    width: 88px;
    height: 2px;
    background-color: rgb(109, 202, 236);
  }

  &::before {
    top: -1px;
  }

  &::after {
    top: 34px;
  }
}

.rmc-picker-item {
  font-size: 20px !important;
  color: #000 !important;
}

.rmc-picker-content {
  padding: 35px 0 0 0 !important;
}
