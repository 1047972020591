@import '../../assets/scss/variable';

.title {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    letter-spacing: 0;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.2;

    gap: 1rem;
}

.wrapper {
    min-height: 50rem;
    gap: 1.5rem;
    @include flex-center(column, flex-start, center);

    @media screen and (min-width: 667px) {
        min-height: 50rem;
        margin: 1.5rem 2.5rem;

        @include flex-center(row, flex-start, center);
    }
}

.profileTab {
    width: 100%;
    height: calc(100vh - 3.4rem);
    border-radius: 2rem;
    background-color: $light-color;

    @media screen and (min-width: 667px) {
        width: 40rem;
    }

    @media screen and (max-width: 666px) {
        height: auto;
    }

    .profileTitle {
        margin: 1.8rem 1.8rem 0 1.8rem;
        padding-bottom: 1.8rem;
        border-bottom: 1px solid $gray-300;
    }

    .tabsWrapper {
        display: flex;
        flex-direction: column;
        margin: 2.5rem 1.8rem;

        gap: 1rem;

        .profileItem {
            width: 100%;
            padding: 1.2rem;
            border-radius: 12px;
            color: $gray-500;
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 1.2;
            cursor: pointer;
            transition: background-color 0.3s, color 0.3s;

            gap: 1rem;
            @include flex-center(row, flex-start, center);

            &.activeTab {
                background-color: $gray;
            }

            svg {
                width: 2.25rem;
                height: 2.25rem;
            }

            img {
                width: 2rem;
                height: 2rem;
                margin-right: 1rem;
            }

            &:hover {
                background-color: #f5f5f5;
            }
        }
    }
}

.profile {
    overflow: auto;
    width: 100%;
    height: calc(100vh - 3.4rem);
    padding: 1.8rem;
    border-radius: 2rem;
    background-color: $light-color;
    transition: all 0.3s ease;

    @media screen and (max-width: 667px) {
        height: 100%;
    }

    & > * {
        padding-bottom: 1.8rem;
        opacity: 0;
        transition: opacity 0.3s ease, transform 0.3s ease;
        transform: translateY(20px);
    }

    & > *:not(.hidden) {
        opacity: 1;
        transform: translateY(0);
    }

    .profileInfo {
        position: relative;
        width: 100%;
        padding: 0 0 2.5rem 0;
        border-radius: 1.25rem;

        @media screen and (max-width: 667px) {
            padding: 0 0 1.5rem 0;

        }
    }

    .titleWrapper {
        border-bottom: 1px solid $gray-300;

        @include flex-center(row, space-between, center);

        h2 {
            padding-bottom: 1.8rem;
        }
    }

    button {
        box-sizing: border-box;
        padding: 0.7rem 2rem;
        border-radius: 1.5rem;
        font-weight: 500;
        font-style: normal;
        font-size: 1.25rem;
        line-height: 1.2;
        width: fit-content;
        @include flex-center(row, flex-start, center);
    }

    .borderBtn {
        border: 1px solid $purple;
        color: $purple;
    }

    .uploadBtn {
        border: none;
        background: $gradient-blue-to-purple-angled;
        color: $light-color;

        &:hover {
            background: $gradient-blue-purple;
        }
    }

    .editButton {
        gap: 0.5rem;
        @include flex-center(row, flex-start, center);

        .icon {
            margin-left: 1rem;
            font-size: 1.8rem;
        }
    }

    .infoWrapper {
        margin-top: 2.25rem;

        @include flex-center(row, space-between, center);

        @media screen and (max-width: 667px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 1.75rem;
            margin-top: 1.25rem;
            margin-bottom: 1.25rem;

        }

        .profilePicture {
            gap: 1.215rem;
            @include flex-center(row, flex-start, center);

            img {
                width: 6rem;
                height: 6rem;
            }
        }

        .avatarInstruction {
            display: flex;
            flex-direction: column;

            gap: 1rem;
        }

        .avatarAction {
            gap: 1rem;
            @include flex-center(row, flex-start, center);
        }
    }
}

.details {
    &.operator {
        margin-top: 2.5rem;

        .row {
            @include flex-center(column, flex-start, flex-start);

            .field {
                width: 100%;
            }
        }
    }

    .row {
        margin-top: 2rem;

        gap: 2rem;
        @include flex-center(row, flex-start, center);

        @media screen and (max-width: 667px) {
            @include flex-center(column, flex-start, flex-start);
            width: 100%;
        }
    }
}

.field {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    margin-bottom: 1rem;

    gap: 1.25rem;

    @media screen and (max-width: 667px) {
        width: 100%;
    }

    &.btnWrapper {
        display: flex;
        flex-direction: row;
        margin-top: 1rem;

        gap: 1rem;
    }

    label {
        color: $gray-500;
        text-align: left;
        font-weight: 600;
        font-size: 1.3rem;
        line-height: 1.2px;
    }

    input:not(input[type='checkbox']) {
        width: 100%;
        padding: 0.9rem;
        border: 1px solid $gray-300;
        border-radius: 12px;
        background-color: $light-color;
        color: $gray-500;
        font-weight: 400;
        font-size: 1.25rem;

        &:disabled {
            cursor: not-allowed;
        }
    }

    .readOnlyInput {
        width: 50%;
    }

    input[type='password'].passwordInput {
        border: 1px solid $gray-300;
        opacity: 0.8;
    }

    .errorText {
        color: $danger;
        font-size: 1rem;
    }
}

.logout {
    margin: 4rem 2rem 2rem 0rem;
    padding: 1.2rem;
    border-radius: 12px;
    color: $danger;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.2;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;

    gap: 1rem;
    @include flex-center(row, flex-start, center);

    @media screen and (max-width: 667px) {
        margin: 2rem 2rem 1rem 0rem;
    }

    img {
        width: 1.5rem;
        height: auto;
    }

    &:hover {
        background-color: #f5f5f5;
    }
}

.btnDelete {
    background: $danger;
    color: $light-color;

    @media screen and (min-width: 667px) {
        width: max-content;
    }

    &:hover {
        background: linear-gradient(to bottom, #fcb0b0, #f15f5f);
    }
}

.passwordField {
    position: relative;
    @include flex-center(row, flex-start, center);
    input {
        flex: 1;
        padding-right: 2rem;
    }

    .eyeIcon {
        position: absolute;
        right: 0.75rem;
        color: #7d8aab;
        font-size: 1.2rem;
        cursor: pointer;

        &:hover {
            color: $gray-500;
        }
    }
}

.titleChangePassword {
    padding-bottom: 1rem;
    border-bottom: 1px solid $gray-300;
    font-weight: 600;
    font-size: 1.7rem;
    line-height: 1.4;
    @include flex-center(row, space-between, center);
}