@import "../../../assets/scss/variable";

.container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 2.2rem 0 1.75rem 1.5rem;

  @media (max-width: 1024px) {
    height: auto;
  }

  @media (max-width: 667px) {
    display: none;
  }

  a {
    letter-spacing: 0;

    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  a.navigation,
  div.navigation {
    display: flex;
    align-items: center;
    flex-direction: row;
    color: $gray-500;
    text-decoration: none;

    font-weight: 500;
    font-size: 1.3rem;
    line-height: 0.7;
    cursor: pointer;

    &:last-child {
      margin: 0;
      padding: 0;
    }

    &:hover {
      border-radius: 12px;
      background: $light-color;

      path {
        fill: none;
      }
    }

    svg,
    > img {
      width: 2rem;
      height: 2rem;
      margin: 0.75rem 1rem 0.75rem 1rem;
    }

    &.navigationActive {
      padding: 0.8rem 1rem;
      border-radius: 1.2rem;
      background: $gradient-blue-to-purple-angled;
      color: $light-color;

      path {
        fill: none;
        stroke: $light-color;
      }

      svg {
        margin: 0rem 1rem 0rem 0rem;
      }

      &:last-child {
        padding: 0.5rem;
      }
    }
  }
}

.newIconWrapper {
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0 0 0 0.5rem;
  border-radius: 50%;
  background-color: $light-color;

  .newNotifications {
    position: absolute;
    top: -2px;
    left: -2px;
    flex-shrink: 0 !important;
    width: 24px !important;
    height: 24px !important;
    margin: 0 !important;

    path {
      fill: $danger !important;
    }
  }
}

.nonActive {
  filter: blur(1px);
  pointer-events: none;
}

.logo {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 1.25rem 0 2.2rem 0;
  font-family: $family_secondary;
  font-weight: 500;
  font-size: 1.9rem;
  line-height: 1.5rem;

  @media screen and (min-width: 667px) {
    font-size: 1.3rem;
  }

  svg {
    width: 2.6rem;
    height: 2.6rem;

    @media screen and (min-width: 667px) {
      width: 2.2rem;
      height: 2.2rem;
      margin: 0 1.25rem 0 1.56rem;
    }
  }
}

.textInfo {
  width: 12rem;
}

.userCard {
  display: flex;
  align-items: center;
  flex-direction: row;

  img {
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;
    border-radius: 8px;
  }

  svg {
    width: 2.25rem;
    height: 2.25rem;
    margin-right: 0.5rem;
    border-radius: 50%;
  }

  &__userInfo {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  &__name {
    overflow: hidden;
    margin-bottom: 0.4rem;
    color: $gray-500;
    text-overflow: ellipsis;
    white-space: nowrap;
    letter-spacing: 0;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.4;
  }

  &__email {
    overflow: hidden;
    color: $gray-500;
    text-overflow: ellipsis;
    white-space: nowrap;
    letter-spacing: 0;

    font-weight: 500;
    font-size: 1.15rem;
    line-height: 140%;
  }

  svg {
    width: 0.4rem;
    height: 0.65rem;
    margin-left: 0.6rem;
    cursor: pointer;
  }
}

.navigationContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.userIcon {
  svg {
    width: 2.25rem;
    height: 2.25rem;
    margin-left: 0;
  }
}

.profile {
  display: flex;
  align-items: center;
  margin-left: 0.8rem;
  text-decoration: none;
  cursor: pointer;

  &.socials {
    height: auto;
  }
}
