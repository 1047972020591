@import '../../assets/scss/variable';

.container {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    min-width: 0;
    padding: 1rem 1.5rem;
    border: 1px solid transparent;
    border-radius: 1rem;

    gap: 1.5rem;

    @media screen and (max-width: 667px) {
        padding: 0.5rem 1rem;
    }

    img {
        width: 5rem;
        height: 5rem;
        margin-right: 1.35rem;
        border-radius: 1.2rem;

        @media screen and (max-width: 1023px) {
            width: 4rem;
            height: 4rem;
        }

        @media screen and (max-width: 667px) {
            width: 3.75rem;
            height: 3.75rem;
            margin-right: 1rem;
        }
    }

    @media screen and (max-width: 667px) {
        padding: 1rem 1.25rem;
    }

    &:hover {
        border-color: $secondary-color;
        background-color: $gray;
        cursor: pointer;
    }

    &.containerActive {
        background: $gray;
    }
}

.unread {
    position: absolute;
    top: 0.9rem;
    left: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 100%;
    background: $gradient-light-blue-purple;

    @media screen and (max-width: 667px) {
        top: 0.6rem;
        left: 0.6rem;
    }

    .quantity {
        color: $light-color;
        font-weight: 600;
        font-size: 0.9rem;
        line-height: 1;
    }
}

.userInfo {
    display: flex;
    flex-direction: column-reverse;
    min-width: 0;
    margin-right: 1rem;

    gap: 0.5rem;
}

.page {
    overflow: hidden;
    max-width: 230px;
    color: $gray-500;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 1.25;
}

.nameWrap {
    display: flex;
    align-items: center;
    flex-direction: row;
    min-width: 0;

    gap: 0.5rem;

    svg {
        width: 1.75rem;
        height: 1.75rem;
    }
}

.name {
    overflow: hidden;
    align-items: center;
    flex: 1 1 auto;
    width: 16rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    letter-spacing: 0;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1;

    gap: 0.5rem;
    ;

    @media screen and (max-width: 1023px) {
        font-size: 1.2rem;
    }
}

.connectionIcon {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
}

.userCard {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.timeStatusWrapper {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    min-width: 12rem;

    gap: 0.25rem;

    .time {
        color: #4c5876;
        letter-spacing: 0;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5;
    }

    .status {
        max-width: 12rem;
        padding: 3px 8px;
        border-radius: 8px;
        background: $purple-light;
        color: $light-color;
        word-break: break-all;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.3;

        @media screen and (max-width: 1024px) {
            padding: 2px 6px;
            border-radius: 5px;
            font-size: 1.1rem;
            line-height: 1.2;
        }
    }
}
