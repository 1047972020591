@import "../../../../../../assets/scss/variable.scss";
.modal {
  background-color: transparentize(#384157, 0.2);
}

.actionsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  background: #e2daff;
  border-radius: 1.25rem;
  padding: 1.5625rem;
  margin-top: 1.25rem;
}

.description {
  align-self: start;
}

.durationPicker {
  background: red;
}

.selectWrapper {
  margin-top: 2.5rem;
  width: 100%;
}

.controls {
  display: flex;
  justify-content: space-between;
  margin-top: 1.25rem;
}

.replyOptionContent {
  margin: 1.5625rem 0;
}

.replyOptionDescription {
  display: block;
  margin: 0.6875rem 0;
  font-size: 0.875rem;
}

.replyOptionTextarea {
  width: 100%;
  min-height: 7.8125rem;
  border: none;
  font-size: 0.875rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  padding: 1rem;
}

.replyOptionExplanation {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  margin-top: 2.5rem;
}

.silenceOptionContent {
  margin-top: 20px;
}

.description,
.inputLabel {
  font-family: "Montserrat", sans-serif;
  font-size: 0.9375rem;
  color: $blue-800;
}

.inputLabel {
  margin-bottom: 0.625rem;
}

.scheduleWrapper {
  display: flex;
  gap: 1.25rem;
}
