@import '../../../../../../../assets/scss/variable';

.containerMessage {
    display: flex;
    flex-direction: column;
}

.message {
    position: relative;
    max-width: 80%;
    margin: 1rem 0;
    padding: 0.5rem;
    border-radius: 1.2rem;
    white-space: pre-wrap;
    word-break: break-word;
    font-size: 1.6rem;

    @media screen and (min-width: 667px) {
        max-width: 48%;
        margin: 0.3rem 0;
        padding: 0.5rem;
        border-radius: 0.8rem;
        white-space: pre-wrap;
        word-break: break-word;
        font-size: 0.9rem;
    }
}

.message.assistant > .specMessageIcon {
    position: absolute;
    right: -8px;
    bottom: -6px;
    display: none;
    color: #439929;
    cursor: pointer;
}

.assistant:hover.message:hover {
    .specMessageIcon {
        display: block;
        opacity: 0.3;
        pointer-events: none;
    }
}

.contentWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 1.2rem;

    &.function {
        .isDebugModeOn {
            margin-left: 5.5rem;
            padding: 1.25rem 1rem;
            border-radius: 0.5rem;
            background-color: $gray;
        }
    }

    &.assistant,
    &.tool  {
        margin-left: 5.5rem;
    }

    &.user {
        padding: 1.25rem 1rem !important;
        background-color: $purple;
        color: $light-color;

        a {
            color: $color-green;

            &:hover {
                color: darken($color-green, 10%);
            }
        }
    }
}

img.assastantAvatar {
    position: absolute;
    bottom: 0;
    left: -5rem;
    width: 4rem;
    height: 4rem;

    @media screen and (max-width: 667px) {
        width: 3rem;
        height: 3rem;
    }
}

.messageDate {
    margin: 10px 0.3rem 0.5rem 5.5rem;
    padding: 0!important;
    background: none;
    color: #7d8aab;
    font-weight: 400;
    font-size: 1.25rem;

    @media screen and (min-width: 668px) and (max-width: 1280px) {
        font-size: 1.1rem;
    }
}

.assistant {
    position: relative;
    align-self: flex-start;
    font-size: 1.25rem;

    .managerWrapper {
        display: flex;
        flex-direction: column;
    }

    .managerNameWrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 1.25rem 1rem 0.5rem;
        border-radius: 0.5rem;
        background-color: $gray;
        transform: translateY(5px);

        gap: 0.75rem;

        svg {
            width: 1.6rem;
            height: 1.6rem;
        }

        .managerName {
            color: $purple;
            font-weight: 500;
            font-size: 1.3rem;
            line-height: 1.2;
        }
    }

    .fileWrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        background-color: #f0f2f4;
    }

    .sentVia {
        right: -1rem;
    }

    @media screen and (min-width: 667px) {
        margin: 0.3rem 0;
        border-radius: 0.8rem;
        white-space: pre-wrap;
        word-break: break-word;
    }

    .messageDate {
        margin: 10px 0.3rem 0.5rem 5.5rem;
    }
}

.user {
    align-self: flex-end;
    min-width: 35%;
    padding: 0 1.3rem;
    font-size: 1.25rem;

    .sentVia {
        left: -1rem;
    }

    .messageDate {
        margin: 10px 0.3rem 0.5rem 0rem;
    }
}

.sentVia {
    position: absolute;
    top: -1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    background: $light-color;

    svg {
        width: 1.4rem;
        height: 1.4rem;
    }
}

.specMessageIcon {
    display: none;
}

.mediaContainer {
    margin-top: 1.5rem;
}
