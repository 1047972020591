@import "../../../../assets/scss/variable";

.enableBotText {
  margin-left: 0.5rem;
  font-size: 1.5rem;

  font-weight: 500;
}

.enableBot {
  padding: 1.25rem;
  display: flex;
  flex-direction: row;
  height: 4.5rem;
  align-items: center;
  background-color: $light-color;
  width: 100%;
  border-radius: 0.8rem;
}

.subTitle {
  font-weight: 600;
  color: $primary-color;
}

.settingsContainer {
  width: 100%;
  border-radius: 0.8rem;
  background-color: $light-color;
  padding: 1.25rem;
}

.optionsContainer {
  display: flex;
  flex-direction: row;
  gap: 1.6875rem;
  margin-top: 1.3125rem;
}

.linksDescription {
  display: block;
  font-weight: 600;
  margin: 0.4375rem 0;
}

.socialLinksContainer {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin-top: 1.5rem;
}

.socialLinks {
  flex-grow: 1;
}

.colorPickerTitle {
  margin-bottom: 3rem;
}

.downloadContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.25rem;
  margin-top: 3.75rem;
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.modalContent {
  display: flex;
  align-items: center;
  gap: 1.25rem;
  width: 100%;
  margin-top: 2rem;
}

.swScriptInput {
  width: 100%;
  margin-top: 0;
}

.copyIcon {
  cursor: pointer;
}

.colorPickerContainer {
  max-width: 40%;
}
