.container {
  display: flex;
  flex-direction: column;
  background-color: #f0f2f4;
  min-height: 100vh;
  @media screen and (min-width: 667px) {
    flex-direction: row;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;

  @media (max-width: 666px) {
    min-height: calc(100vh - 60px);
  }
  @media (min-width: 667px) {
    margin-left: 20rem;
  }
}

.wrapper::-webkit-scrollbar {
  width: 0.6rem;
  background-color: transparent;
}

.wrapper::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 0.3rem;
}

.loaderContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
