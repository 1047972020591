:root {
  --fontFamilyPrimary: "Montserrat", serif;
  --fontFamilySecondary: Evolventa, sans-serif;
  --colorPrimaty: #000;
}
#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

html {
  font-size: 10px;
}
@media screen and (min-width: 667px) {
  html {
    font-size: 4.63px;
  }
}
@media screen and (min-width: 768px) {
  html {
    font-size: 5.3px;
  }
}
@media screen and (min-width: 992px) {
  html {
    font-size: 6.9px;
  }
}
@media screen and (min-width: 1024px) {
  html {
    font-size: 7.1px;
  }
}
@media screen and (min-width: 1280px) {
  html {
    font-size: 8.9px;
  }
}
@media screen and (min-width: 1366px) {
  html {
    font-size: 9.5px;
  }
}
@media screen and (min-width: 1440px) {
  html {
    font-size: 10px;
  }
}
@media screen and (min-width: 1536px) {
  html {
    font-size: 10.7px;
  }
}
@media screen and (min-width: 1600px) {
  html {
    font-size: 11.1px;
  }
}
@media screen and (min-width: 1680px) {
  html {
    font-size: 11.7px;
  }
}
@media screen and (min-width: 1700px) {
  html {
    font-size: 11.8px;
  }
}
@media screen and (min-width: 1920px) {
  html {
    font-size: 13.3px;
  }
}
@media screen and (min-width: 2560px) {
  html {
    font-size: 17.8px;
  }
}

* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

:focus,
:active {
  /*outline: none;*/
}

a:focus,
a:active {
  /*outline: none;*/
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  color: var(--colorPrimaty);
  font-family: var(--fontFamilyPrimary);
  /*text-rendering: optimizeLegibility;*/
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.no-scroll {
    overflow: hidden;
  }
}

input,
button,
textarea,
optgroup,
select {
  font-family: var(--fontFamilyPrimary);
  font-size: inherit;
}

button {
  cursor: pointer;
  color: inherit;
  background-color: inherit;
  /*прибрати синє миготіння в хромі при кліку*/
  -webkit-tap-highlight-color: transparent;
}

button:disabled {
  cursor: auto;
}

picture {
  display: block;
}

p {
  margin: 0;
  padding: 0;
}

a {
  cursor: pointer;
  display: inline-block;
  color: inherit;
  /*прибрати синє миготіння в хромі при кліку*/
  -webkit-tap-highlight-color: transparent;
}

a:hover {
  text-decoration: none;
}

ol,
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /*vertical-align: top;*/
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
  padding: 0;
  margin: 0;
}

blockquote {
  margin: 0;
  padding: 0;
}

/*прибрати стрілки для input type="number"*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.recording.audio-recorder {
  width: 500px;
  color: red;
}

.audio-recorder .audio-recorder-status {
  display: none;
}

.audio-recorder .audio-recorder-timer {
  margin: 0;
}

.audio-recorder [data-testid="ar_pause"] {
  display: none;
}

.audio-recorder [data-testid="ar_cancel"] {
  width: 5rem;
  height: 4.4rem;
  margin: 0;
}

.audio-recorder {
  position: relative;
}

.audio-recorder:after {
  content: "";
  position: absolute;
  background-size: 6.7rem 6.7rem;
  top: 0;
  right: 50%;
  transform: translateX(50%);
  width: 6.7rem;
  height: 6.7rem;
  display: block;
  pointer-events: none;
}

.audio-recorder [title="Save recording"] {
  display: none;
}

.react-player {
  margin: 0 auto;
  width: 27rem !important;
  height: 15rem !important;
  @media screen and (min-width: 667px) {
    width: 42rem !important;
    height: 42rem !important;
  }
}
.react-player__play-icon {
  position: relative;
  border: none !important;
  width: 4rem;
  height: 4rem;
  margin-left: 0 !important;
  border-color: transparent !important;
}
.react-player__play-icon:after {
  content: "";
  position: absolute;
  width: 40rem;
  height: 40rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-size: 4rem 4rem;
  background-repeat: no-repeat;
  z-index: 4;
}

.rdp-masked-div {
  background: #e2daff;
  mask-image: none !important;
  -webkit-mask-image: none !important;
}

.rdp-text-overlay {
  color: #e2daff;
}

::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  padding-left: 20px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #d8d8d8;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #c6b9fa;
}

::-webkit-scrollbar-thumb:hover {
  background: #a7abf9;
  cursor: pointer;
}

.nowrap {
  display: flex;
  flex-wrap: nowrap;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
}

.rdrMonth,
.rdrMonthAndYearWrapper {
  padding: 0;
}

.rdrDayNumber {
  & > span {
    &::after {
      display: none;
    }
  }
}

.rdrWeekDays,
.rdrDays {
  border-bottom: 1px solid #e3e6ee;
}

.rdrDays {
  padding-bottom: 1rem;
}

.rdrDay:not(.rdrDayPassive) .rdrStartEdge,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge {
  background-color: #777ef5;
}

.rdrDayNumber {
  z-index: 10000;
}

.rdrInRange {
  background-color: #aaaeff;
}
