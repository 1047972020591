.reactMarkDown {
    margin-bottom: 5px;
  }

  .reactMarkDown img {
    display: block;
    margin: 0 0 4px 0;
    max-width: 100%;
    border-radius: 4px;
    margin-top: 3px;
    max-height: 450px;
    max-width: 415px;
    background: #e5e5ea;
    object-fit: contain;
    aspect-ratio: 3/2 auto;
  }


  .reactMarkDown p,
  .reactMarkDown h1,
  .reactMarkDown h2,
  .reactMarkDown h3,
  .reactMarkDown h4,
  .reactMarkDown a,
  .reactMarkDown div,
  .reactMarkDown strong,
  .reactMarkDown li {
    font-size: 1.3rem;
    line-height: 1.4;
    color: #fff;


    @media screen and (min-width: 668px) and (max-width: 1280px) {
        font-size: 1.45rem;
    }

    @media screen and (max-width: 667px) {
        font-size: 1.25rem;
    }
  }

  .reactMarkDown li p {
    margin: 8px 0px 8px 0px;
    display: flex;
    flex-direction: column;
  }

  .reactMarkDown a {
    font-weight: 600;
    color: #777ef5;
    font-size: 1.25rem;
    line-height: 1.4;
    margin: 5px 0;
  }

  .reactMarkDown ol,
  .reactMarkDown ul {
    line-height: 0px;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
    list-style-type: none;
  }

  .reactMarkDown li {
    padding: 3px 0px;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
    line-height: 1.4;
  }

  .reactMarkDown ol li strong {
    display: inline-block;
    margin-bottom: 0.75rem;
    color: #384157;
    font-size: 1.3rem;
    line-height: 1.4;
  }

  .reactMarkDown p strong {
    color: #384157;
  }

  /* For removing dot coming before image */
  .reactMarkDown li:has(img)::before {
    content: none;
  }