@import '../../assets/scss/variable';

.container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 1.2rem 1.1rem;
    border: 1px solid transparent;
    margin: 0.5rem 0;
    border-radius: 0;
    cursor: pointer;
    gap: 1rem;


    @media screen and (max-width: 667px) {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: 6rem;
        padding: .75rem 1rem;
        border-radius: 0;
    }

    &.nonActive {
        opacity: 0.6;
        cursor: not-allowed;
        pointer-events: none;
    }

    .multipro {
        background: #e2daff!important;
        color: $purple;
    }

    &:hover {
        border-radius: 1.2rem;
        border: 1px solid #7D8AAB
    }
}

.title {
    margin-left: 1.8rem;
    letter-spacing: 0;

    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.5rem;

    @media screen and (max-width: 667px) {
        margin-left: 1.8rem;
        font-size: 1.4rem;
    }
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0.5rem 1rem;
    border-radius: 3rem;
    background-color: #3588e9;
    color: $light-color;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 1.4;
    opacity: 1;
    max-width: 11rem;
    transition: opacity 0.2s;

    @media screen and (max-width: 667px) {
        border-radius: 1.5rem;
        font-size: 1.3rem;
        max-width: 11rem;
    }

    &:hover {
        opacity: .8;
    }

    span {
        font-size: 1.05rem;
        font-weight: 500;
    }
}

.iconContainer {
    display: flex;
    align-items: center;
    flex-direction: row;

    @media screen and (max-width: 667px) {
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    img,
    svg {
        width: 4.3rem;
        height: 4.3rem;

        @media screen and (max-width: 667px) {
            width: 3.1rem;
            height: 3.1rem;
        }
    }
}